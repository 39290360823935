import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tab } from 'semantic-ui-react';

import Config from '../../constants/Config';

import styles from './AboutPane.module.scss';

const AboutPane = React.memo(() => {
  const [t] = useTranslation();

  return (
    <Tab.Pane attached={false} className={styles.wrapper}>
      <div className={styles.version}>
          <p>{t('common.cradedby')}</p>
      </div>
    </Tab.Pane>
  );
});

export default AboutPane;
